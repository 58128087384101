import { render, staticRenderFns } from "./b2b-migration-wizard-redirect.vue?vue&type=template&id=0e8e7055&scoped=true&"
import script from "./b2b-migration-wizard-redirect.vue?vue&type=script&lang=js&"
export * from "./b2b-migration-wizard-redirect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../common/temp/node_modules/.pnpm/vue-loader@15.10.1_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e8e7055",
  null
  
)

export default component.exports