var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    attrs: { "data-test-id": "b2b-migration-wizard-redirect" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }