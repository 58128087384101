<template>
  <div data-test-id="b2b-migration-wizard-redirect"></div>
</template>

<script>
import { checkIfMigrationCandidate, getMigrationStatus } from "./services/migration-service";
import { hasPermission } from "@telia/b2b-logged-in-service";
import { pathToRegexp } from "path-to-regexp";

export default {
  name: "b2b-migration-wizard-redirect",
  data() {
    return {
      groupId: null,
      isMigrationCandidate: false,
      masterGroupId: null,
      shouldGoToWizard: null,
    };
  },
  async created() {
    this.groupId = this.getGroupIdFromUrl();
    await this.initializeValues();
    if (this.shouldRedirectUser) {
      this.maybeRedirectToWizard();
    }
  },
  computed: {
    onboardingWatched() {
      return window.localStorage.getItem("onboardingWatched") === "1";
    },

    cookieConsent() {
      return window.localStorage.getItem("cookieConsentTimestamp");
    },

    hasGroupId() {
      return this.groupId !== null;
    },

    isOnCots() {
      return window.location.hostname.indexOf("cots") >= 0;
    },
  },

  methods: {
    linkUrl() {
      if (this.masterGroupId) {
        return `/foretag/mybusiness/${this.masterGroupId}/hantera/edb-sra-migrering`;
      }
      return null;
    },

    maybeRedirectToWizard() {
      if (this.linkUrl()) {
        window.location.replace(this.linkUrl());
      }
      return;
    },

    shouldRedirectUser() {
      return (
        this.isMigrationCandidate &&
        (this.shouldGoToWizard || this.showNotification) &&
        this.linkUrl() !== null &&
        this.onboardingWatched &&
        this.cookieConsent
      );
    },

    async initializeValues() {
      if (
        this.isOnCots ||
        !this.hasGroupId ||
        !(await this.hasBannerPUI()) ||
        !this.enabledUrls()
      ) {
        return;
      }
      await this.setIsMigrationCandidateAndSetMasterGroupId();
      await this.setWizardMigrationStatus();
    },

    async setIsMigrationCandidateAndSetMasterGroupId() {
      const response = await checkIfMigrationCandidate(this.groupId);
      this.isMigrationCandidate = response.migrationCandidate;
      if (!this.isMigrationCandidate) {
        return;
      }
      this.masterGroupId = response.masterGroupId;
    },

    async setWizardMigrationStatus() {
      const migrationStatus = await getMigrationStatus(this.groupId);

      if (!migrationStatus) {
        return;
      } else {
        const { scheduled, finished, wizardStarted, initiator } = migrationStatus;

        const wizardStartedButNotScheduledOrFinished =
          wizardStarted && initiator && !scheduled && !finished;
        this.shouldGoToWizard = !wizardStarted || wizardStartedButNotScheduledOrFinished;
      }
    },

    getGroupIdFromUrl() {
      const groupIdMatch = window.location.pathname.match(/5\d{8}/);
      if (groupIdMatch !== null) {
        return groupIdMatch[0];
      }
      return null;
    },

    async hasBannerPUI() {
      try {
        return await hasPermission("SRA_MIGRATION_BANNER");
      } catch (error) {
        return false;
      }
    },

    enabledUrls() {
      const scopeIdRegexp = pathToRegexp("/foretag/mybusiness/:scopeId(\\w+)");
      return scopeIdRegexp.exec(window.location.pathname) !== null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
